import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environment';
import { IResp, ILatestUpdate } from './home/home.interface';

@Injectable({
    providedIn: 'root',
})
export class MyApiService {
    private sliderApiUrl = `${environment.APP_URL}/api/futureVision/website/home/slider-image`; // Replace with your API URL
    private popularCourseApiUrl = `${environment.APP_URL}/api/futureVision/website/home/popular-course`;
    private achiversApiUrl = `${environment.APP_URL}/api/futureVision/website/home/top-achiever`;
    private contactFromApiUrl = `${environment.APP_URL}/api/futureVision/website/form-data/contact-form`;
    private sampleExamApiUrl = `${environment.APP_URL}/api/futureVision/website/course/sample-exam/list`;
    private courseCategory = `${environment.APP_URL}/api/futureVision/website/course/category`;
    constructor(private http: HttpClient) {}
    // GET request
    getCoursesData(): Observable<any> {
        return this.http.get<any>(
            `${environment.APP_URL}/api/futureVision/website/course/category/list`
        );
    }

    getGalleryInfrastructureData(): Observable<any> {
        return this.http.get<any>(
            `${environment.APP_URL}/api/futureVision/website/gallery/infrastructure`
        );
    }

    getAchieverData(data: any): Observable<any> {
        return this.http.post<any>(
            `${environment.APP_URL}/api/futureVision/website/gallery/achiever/list`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    getCoursesCategoryData(id: any): Observable<any> {
        return this.http.get<any>(
            `${environment.APP_URL}/api/futureVision/website/course/category?categoryId=${id}`
        );
    }

    getCoursesCategoryDataByName(name: string): Observable<any> {
        return this.http.get<any>(
            `${environment.APP_URL}/api/futureVision/website/course/category/by-name?name=${name}`
        );
    }

    getData(id: any): Observable<any> {
        if (id) {
            return this.http.get<any>(
                `${this.courseCategory}?categoryId=${id}`
            );
        } else {
            return this.http.get<any>(`${this.sliderApiUrl}`);
        }
    }

    getPopularCourseData(): Observable<any> {
        return this.http.get<any>(`${this.popularCourseApiUrl}`);
    }

    getAchieversData(data): Observable<any> {
        return this.http.post<any>(`${this.achiversApiUrl}`, data);
    }

    // POST request
    postData(data: any): Observable<any> {
        return this.http.post<any>(`${this.sliderApiUrl}/data`, data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        });
    }

    // POST request
    postContactData(data: any): Observable<any> {
        return this.http.post<any>(`${this.contactFromApiUrl}`, data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        });
    }

    // POST request
    postSampleExamData(data: any): Observable<any> {
        return this.http.post<any>(
            `${this.sampleExamApiUrl}?pageNo=${data.pageNo}&limit=${data.limit}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    getSampleVideoData(data: any): Observable<any> {
        return this.http.post<any>(
            `${
                environment.APP_URL
            }/api/futureVision/website/course/sample-video?canPaginate=${false}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
    getSampleMaterialData(data: any): Observable<any> {
        return this.http.post<any>(
            `${environment.APP_URL}/api/futureVision/website/course/sample-material?pageNo=${data.pageNo}&limit=${data.limit}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    getCourseDetails(courseName: string): Observable<any> {
        return this.http.post<any>(
            `${environment.APP_URL}/api/futureVision/website/course/detail`,
            { courseName },
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            }
        );
    }

    getAllLatestUpdates({
        categoryIds,
    }: { categoryIds?: string[] } = {}): Observable<
        IResp<{ list: ILatestUpdate[] }>
    > {
        return this.http.post<IResp<{ list: ILatestUpdate[] }>>(
            `${
                environment.APP_URL
            }/api/futureVision/website/home/latest-update/list?canPaginate=${false}`,
            { categoryIds },
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            }
        );
    }
}
