<!-- Gallery Items -->
<div class="gallery-area pt-100 pb-70">
    <div class="container">
        <div *ngIf="_album.length === 0" class="empty-message">
            <p>No exam available.</p>
        </div>
        <div class="row" *ngIf="_album.length > 0">
            <div class="col-lg-3 col-xl-3 col-md-6 col-sm-6" *ngFor="let exam of _album; let i = index;">
                <div class="single-gallery-item" (click)="openExam(exam.url)">
                    <!-- Thumbnail or iframe placeholder -->
                    <!-- <img [src]="getThumbnailUrl(video.videoId)" (click)="openModal(video.videoId)" /> -->

                    <div class="courses-image">

                        <img [src]="exam.image"  />


                    </div>
                    <br>
                    <div class="courses-content">
                        <h3 style="font-size: 16px;">{{exam.name}}</h3>
                        <br>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="pagination-area text-center">
                <a *ngIf="currentPage > 1" (click)="onPageChange(currentPage - 1)" class="prev page-numbers">
                    <i class='bx bx-chevrons-left'></i>
                </a>

                <span *ngFor="let page of visiblePages" (click)="onPageChange(page)"
                    [ngClass]="{'page-numbers': true, 'current': page === currentPage}"
                    [attr.aria-current]="page === currentPage ? 'page' : null">
                    {{ page }}
                </span>

                <a *ngIf="currentPage < totalPages" (click)="onPageChange(currentPage + 1)" class="next page-numbers">
                    <i class='bx bx-chevrons-right'></i>
                </a>
            </div>

        </div>
    </div>
</div>
