<footer class="footer-area">
    <div class="mx-8">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6 col-xl-4">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logowide.png" style="width:120px;height:40px" alt="logo"></a>
                 <p>With a dedicated team of experienced faculty and a commitment to quality education, we have established ourselves as the leading coaching institute in the region.</p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/futurevisionneet" class="d-block cursor-pointer" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/future_vision_study_centre" class="d-block cursor-pointer" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.youtube.com/@FUTUREVISIONNEETACADEMY-jg3qr" class="d-block cursor-pointer" target="_blank"><i class='bx bxl-youtube'></i></a></li>

                        <li><a href="https://t.me/futurevisionneetacademy" class="d-block cursor-pointer" target="_blank"><i class='bx bxl-telegram'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 col-xl-2">
                <div class="single-footer-widget pl-5">
                    <h3>Explore</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about">About us</a></li>
                        <li><a routerLink="/gallery/achievers">Our Achievers</a></li>
                        <li><a routerLink="/gallery/infra">Our Infra</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 col-xl-3">
                <div class="single-footer-widget">
                    <h3>Future Vision TNPSC Academy</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>FUTURE VISION STUDY CENTRE, 2nd Floor, AVK Maruthi Plaza, Opp.Hotel Lakshmi Prakash, New Bus Stand, Salem-636004</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+9190420 30163">+91 90420 30163</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:futurevisionteam&#64;gmail.com">futurevisionteam&#64;gmail.com</a></li>

                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 col-xl-3">
                <div class="single-footer-widget">
                    <h3>Future Vsion NEET Academy </h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>FUTURE VISION STUDY CENTRE, 2nd Floor, AVK Maruthi Plaza, Opp.Hotel Lakshmi Prakash, New Bus Stand, Salem-636004</li>
                        <li><i class='bx bx-phone-call'></i>Contact Number<a href="tel:+919443922215">+91 94439 22215</a></li>
                        <li><i class='bx bx-phone-call'></i>WhatsApp Number<a href="tel:+91944922215">+91 94492 2215</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:futurevisionneet&#64;gmail.com">futurevisionneet&#64;gmail.com</a></li>
                    </ul>
                </div>
            </div>

        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>Powered by <a href="https://zerobugz.com/" target="_blank">Zerobugz</a></p>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a href="https://futurevisioniasacademy.com/refund_policy" target="_blank">Refund Policy</a></li>
                        <li><a href="https://futurevisioniasacademy.com/privacy_policy" target="_blank">Privacy Policy</a></li>
                        <li><a href="https://futurevisioniasacademy.com/terms_conditions" target="_blank">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
