import {Component, OnDestroy, OnInit} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { MyApiService } from '../my-api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent implements OnInit, OnDestroy {
  constructor(private myApiService: MyApiService,private toastrService: ToastrService, private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {

      // Set the title for the page
      this.titleService.setTitle('Contact us – Future Vision Study Centre');

      // Set the meta description for the page
      this.metaService.updateTag({ name: 'description', content: 'Our Address: FUTURE VISION STUDY CENTRE, 2nd floor AVK Maruthi Plaza, Opp. Hotel Lakshmi Prakash, New Bus Stand, Salem – 636004' });

      // You can also add other meta tags like keywords, author, etc.
      this.metaService.updateTag({ name: 'keywords', content: 'contact, angular, seo, website' });
  }

    ngOnDestroy() {
        // Set the title for the page
        this.titleService.setTitle('Future Vision Study Centre');

        // Set the meta description for the page
        this.metaService.updateTag({ name: 'description', content: 'Welcome to Future Vision Study Centre' });

        // You can also add other meta tags like keywords, author, etc.
        this.metaService.updateTag({ name: 'keywords', content: 'future vision, angular, seo, website' });
    }

  bgImage = [
      {
          img: 'assets/img/register-shape.jpg'
      }
  ]

  submit(form){


      this.myApiService.postContactData({name:form.name,location:form.location,mobile:form.number,msg:form.message})
      .subscribe((data)=>{

       form.name=''
       form.location=''
       form.mobile=''
       form.message=''
       this.toastrService.success(`Thank you for reaching out! We'll be in touch with you soon`, 'Contact Info!');



      })
  }

}
