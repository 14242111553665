<!-- Gallery Items -->
<div class="gallery-area pt-100 pb-70">
    <div class="container">
        <div *ngIf="_album.length === 0" class="empty-message">
            <p>No videos available.</p>
        </div>
        <div class="row" *ngIf="_album.length > 0">
            <div class="col-lg-3 col-xl-3 col-md-6 col-sm-6" *ngFor="let video of _album; let i = index;">
                <div class="single-gallery-item">
                    <!-- Thumbnail or iframe placeholder -->
                    <img [src]="getThumbnailUrl(video.videoId)" (click)="openModal(video.videoId)" />
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="pagination-area text-center">
                <a *ngIf="currentPage > 1" (click)="onPageChange(currentPage - 1)" class="prev page-numbers">
                    <i class='bx bx-chevrons-left'></i>
                </a>

                <span
                    *ngFor="let page of visiblePages"
                    (click)="onPageChange(page)"
                    [ngClass]="{'page-numbers': true, 'current': page === currentPage}"
                    [attr.aria-current]="page === currentPage ? 'page' : null"
                >
                    {{ page }}
                </span>

                <a *ngIf="currentPage < totalPages" (click)="onPageChange(currentPage + 1)" class="next page-numbers">
                    <i class='bx bx-chevrons-right'></i>
                </a>
            </div>

        </div>
    </div>
</div>


<div class="modal" [ngClass]="{'modal-open': isModalVisible}">
    <div class="modal-content">
        <span class="close" (click)="closeModal()">&times;</span>
        <iframe [src]="videoUrl" width="100%" height="auto" frameborder="0" allowfullscreen></iframe>
    </div>
</div>
