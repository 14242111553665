import { Component, OnInit, HostListener } from '@angular/core';
import { MyApiService } from '../../custom/my-api.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    courses: any[] = []
    // Navbar Sticky
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    constructor(private apiService: MyApiService) { }

    ngOnInit(): void {
        this.apiService.getCoursesData().subscribe((res: any)=>{
            if(res?.error){
                throw res.error
            }
            if(res?.data?.data)
                this.courses = res?.data?.data
        })
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

}
