import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Title, Meta } from '@angular/platform-browser';

import { ActivatedRoute } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { MyApiService } from '../../my-api.service';

@Component({
    selector: 'app-sample-video',

    templateUrl: './sample-video.component.html',
    styleUrl: './sample-video.component.scss',
})
export class SampleVideoComponent implements OnInit {
    totalItems: number = 0;
    itemsPerPage: number = 15;
    currentPage: number = 1;
    totalPages: number = 0;
    pages: number[] = [];
    _album = [];

    isVisible: any = false;

    constructor(
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private myApiService: MyApiService,
        private titleService: Title,
        private metaService: Meta,
    ) {}

    ngOnInit(): void {
        this.route.queryParamMap.subscribe((params) => {
            // 'id' should match the query parameter name

            this.route.paramMap.subscribe((params) => {
                const courseName = params.get('courseName');
                if (courseName) {
                    this.loadPageData(this.currentPage);
                }
            });
        });

        this.titleService.setTitle(
            'Sample Classes – Future Vision Study Centre',
        );

        // Set the meta description for the page
        this.metaService.updateTag({
            name: 'description',
            content:
                'For Over a decade, Future Vision Study Centre has been a beacon of excellence in education, providing specialized coaching for NEET, TNPSC, and TRB exams in Salem.',
        });

        // You can also add other meta tags like keywords, author, etc.
        this.metaService.updateTag({
            name: 'keywords',
            content: 'sample-classes, angular, seo, website',
        });
    }

    loadPageData(page: number): void {
        this.myApiService
            .getSampleVideoData({
                courseNames: [this.route.snapshot.paramMap.get('courseName')],
                pageNo: page,
                limit: this.itemsPerPage,
            })
            .subscribe((res: any) => {
                if (res?.error) {
                    throw res.error;
                }
                if (res?.data) {
                    if (res.data.list.length) {
                        this._album = res.data.list.map((e: any) => ({
                            videoId: e.material.url,
                        }));
                        this.totalItems = res.data.count;
                        this.totalPages = Math.ceil(
                            this.totalItems / this.itemsPerPage,
                        );
                        if (this.totalPages > 1) {
                            this.updateVisiblePages();
                        }
                        console.log(this._album);
                    }
                }
            });
    }
    visiblePages: number[] = [];
    updateVisiblePages(): void {
        const maxVisiblePages = 5;
        let startPage = Math.max(
            1,
            this.currentPage - Math.floor(maxVisiblePages / 2),
        );
        let endPage = Math.min(
            this.totalPages,
            startPage + maxVisiblePages - 1,
        );

        // Adjust startPage if we're near the end of the totalPages
        if (
            endPage - startPage + 1 < maxVisiblePages &&
            endPage === this.totalPages
        ) {
            startPage = Math.max(1, this.totalPages - maxVisiblePages + 1);
        }

        this.visiblePages = Array.from(
            { length: endPage - startPage + 1 },
            (_, i) => startPage + i,
        );
    }

    videoUrl: SafeResourceUrl;
    isModalVisible = false;
    openModal(videoId: string) {
        const url = `https://www.youtube.com/embed/${videoId}?autoplay=1`;
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        console.log(this.videoUrl);
        this.isModalVisible = true;
    }

    closeModal() {
        this.isModalVisible = false;
        // Optionally reset iframe source to stop video
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    }

    getThumbnailUrl(videoId: string): string {
        return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    }

    onPageChange(pageNumber: number): void {
        this.currentPage = pageNumber;
        this.loadPageData(this.currentPage);
    }
}
