<div class="apply-instructor-area pb-70">
    <div class="container">
        <div class="odemy-grid-sorting row align-items-center">
            <div class="col-lg-8 ordering" style="text-align: unset !important;">
                <div class="select-box">
                    <label>Course Category:</label>
                    <select (change)="onCourseSelect($event)">
                        <option
                            *ngFor="
                                let course of courses;
                                trackBy: trackByCourseId
                            "
                            [value]="course._id"
                        >
                            {{ course.name }}
                        </option>
                    </select>
                    &nbsp;
                    <label>Course:</label>
                    <select (change)="onSubCourseSelect($event)">
                        <option
                            *ngFor="let course of subCourses"
                            [value]="course._id"
                        >
                            {{ course.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <h4 class="pb-30" style="margin-bottom: 30px;">{{ currentTab }}</h4>

            <!-- Check if achievers array is empty -->
            <div *ngIf="achievers.length === 0" class="col-12">
                <p>No records found</p>
            </div>

            <!-- Loop through achievers if array is not empty -->
            <div
                *ngFor="let achiever of achievers"
                class="col-lg-2 col-md-3 col-sm-6 col-6"
            >
                <div class="start-with-success-box">
                    <div class="image">
                        <a routerLink="/success-story">
                            <img
                                [src]="baseUrl + achiever.attachment.path"
                                [alt]="achiever.name"
                            />
                        </a>
                    </div>
                    <div class="content" style="text-align: center; margin-top: 10px;">
                        <h3 class="item-name">{{ achiever.name }}</h3>
                        <span class="item-desc">{{ achiever.desc1 }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
