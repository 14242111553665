<div class="about-area-three ptb-70" style="background-color: antiquewhite;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <span class="sub-title">Welcome to Future Vision Study Centre</span>
                    <h2>Best Neet academy in salem</h2>
                    <p>For over a decade, Future Vision Study Centre has been a beacon of excellence in education, providing specialized
                        coaching for NEET, TNPSC, and TRB exams in Salem. Our mission is to empower students with the knowledge,
                        skills, and confidence to achieve their academic and career goals.
                        </p>
                    <p><strong>With a dedicated team of experienced faculty and
                        a commitment to quality education, we have established ourselves as the leading coaching institute in the region.</strong></p>
                    <a routerLink="/about-custom" class="default-btn"><i class="flaticon-user"></i>About<span></span></a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-img">
                    <div class="image">
                        <img src="assets/img/about-1.png" alt="image">
                        <img src="assets/img/about-2.png" alt="image">
                    </div>
                    <div class="shape17"><img src="assets/img/shape16.png" alt="image"></div>
                    <div class="shape18"><img src="assets/img/shape17.png" alt="image"></div>
                    <div class="shape19"><img src="assets/img/shape18.png" alt="image"></div>
                    <div class="shape20"><img src="assets/img/shape19.png" alt="image"></div>
                    <div class="shape21"><img src="assets/img/shape20.png" alt="image"></div>
                    <div class="shape22"><img src="assets/img/shape21.png" alt="image"></div>
                    <div class="shape23"><img src="assets/img/shape22.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
</div>
