<div id="demo" class="carousel slide" data-ride="carousel" data-interval="3000">
  <!-- The slideshow -->
  <div id="carouselExampleIndicators" class="carousel-inner">
    <div class="carousel-item" *ngFor="let image of data; let first = first" [class.active]="first">
      <img [src]="image.path" [alt]="image.name" class="d-block w-100">
    </div>
  </div>

    <!-- Left and right controls -->
    <a class="carousel-control-prev" href="#demo" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#demo" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div>
