import { Component } from '@angular/core';
import { MyApiService } from '../../my-api.service';

@Component({
  selector: 'app-achiever',
  templateUrl: './achiever.component.html',
  styleUrl: './achiever.component.scss'
})
export class AchieverComponent {
  data: any;
  constructor(private myApiService: MyApiService) { }
  ngOnInit(): void {
    // Call GET method

    this.myApiService.getAchieversData({}).subscribe(response => {
     console.log(response.data.achievers.length)
      if (response.data.achievers.length > 0) {
        this.data = response.data.achievers;
        this.data = this.data.map(({_id, name, desc1, desc2, attachment}) => {
          console.log(_id, name, desc1, desc2, attachment)
          return {
            _id,
            name,
            desc1,
            imgPath: attachment ? `https://futurevisioniasacademy.com/${attachment.path}` : "assets/img/1371716613931.jpg",
          }
        })
       
      } else {
        this.data = [
          {
            "_id": "66a50215a004163f0c42819c",
            "name": "Dhayalan",
            "attachment": {
              "path": "/mediaFiles/futureVision/version1/website/1722089990447_file_name-rupee.PNG",
              "name": "rupee.PNG",
              "type": "image",
              "size": "2592"
            },
            "desc1": "VAO",
            "desc2": "29-10-2024",
            "desc3": "."
          },
          {
            "_id": "66a5024da004163f0c4281e2",
            "name": "Hari prasanth",
            "attachment": {
              "path": "/mediaFiles/futureVision/version1/website/1722090056567_file_name-kisspng-clip-art-computer-icons-openclipart-user-vector-gr-my-svg-png-icon-free-download-14-352-onlinewe-5c7704f7cff2b1.3948772115513039278518.jpg",
              "name": "kisspng-clip-art-computer-icons-openclipart-user-vector-gr-my-svg-png-icon-free-download-14-352-onlinewe-5c7704f7cff2b1.3948772115513039278518.jpg",
              "type": "image",
              "size": "73201"
            },
            "desc1": "IAS",
            "desc2": "12-12-2012",
            "desc3": "sample"
          },
          {
            "_id": "66a50270a004163f0c4281f8",
            "name": "Boopathi",
            "attachment": {
              "path": "/mediaFiles/futureVision/version1/website/1722090094658_file_name-download.png",
              "name": "download.png",
              "type": "image",
              "size": "19374"
            },
            "desc1": "IPS",
            "desc2": "12-14-2025",
            "desc3": "sample1"
          }
        ]
      }

    }, error => {
      console.error('Error fetching data', error);
    });

  }

}
