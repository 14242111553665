<div class="start-with-success-area pt-100 bg-f8f9f8 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title"
                >SHINING STARS - OUR TOP PERFORMERS OF THE YEAR</span
            >
            <h2>Our Achievers</h2>
        </div>
        <div class="row">
            <div
                class="col-lg-2 col-md-3 col-sm-6 col-6"
                *ngFor="let item of data"
            >
                <div class="start-with-success-box">
                    <div class="image">
                        <img [src]="item.imgPath" [alt]="item.name" />
                    </div>
                    <div
                        class="content"
                        style="text-align: center; margin-top: 10px"
                    >
                        <h3 class="item-name achiever-student-name">{{ item.name }}</h3>
                        <span class="item-desc achiever-student-desc">{{ item.desc1 }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
