<div class="row text-center">
    <div class="custom-line"></div>

    <div
        *ngFor="let fee of fees"
        class="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 fw-bold"
    >
        <div>
            {{ fee.type === "Online" ? "Online" : "Direct" }}
            Class Fee
        </div>
        <div class="text-danger">INR {{ fee.fee }}</div>
    </div>
    <div class="custom-line"></div>
</div>
