import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

import { filter } from 'rxjs/operators';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit, OnDestroy {

    title: any;
    location: any;
    routerSubscription: any;

    constructor(private router: Router, private titleService: Title, private metaService: Meta) {}

    ngOnInit(){
        this.recallJsFuntions();

        // Set the title for the page
        this.titleService.setTitle('Home – Welcome to Future Vision Study Centre');

        // Set the meta description for the page
        this.metaService.updateTag({ name: 'description', content: 'For Over a decade, Future Vision Study Centre has been a beacon of excellence in education, providing specialized coaching for NEET, TNPSC, and TRB exams in Salem.' });

        // You can also add other meta tags like keywords, author, etc.
        this.metaService.updateTag({ name: 'keywords', content: 'home, angular, seo, website' });
    }

    ngOnDestroy() {
        // Set the title for the page
        this.titleService.setTitle('Future Vision Study Centre');

        // Set the meta description for the page
        this.metaService.updateTag({ name: 'description', content: 'Welcome to Future Vision Study Centre' });

        // You can also add other meta tags like keywords, author, etc.
        this.metaService.updateTag({ name: 'keywords', content: 'future vision, angular, seo, website' });
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

}
