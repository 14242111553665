<!-- assets/img/1371716613931.jpg -->
<app-carousel></app-carousel>
<app-coursehighlight></app-coursehighlight>

<app-about></app-about>
<app-achiever></app-achiever>


<!-- <app-elearning-banner></app-elearning-banner> -->


<!-- <div class="partner-area pt-100 pb-70 border-bottom">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>

<div class="features-area pt-100 pb-70">
    <app-features-style-one></app-features-style-one>
</div>

<app-elearning-about></app-elearning-about>

<div class="courses-area ptb-100">
    <app-courses></app-courses>
</div>

<app-funfacts-feedback></app-funfacts-feedback>

<app-get-instant-courses></app-get-instant-courses>

<div class="blog-area ptb-100">
    <app-blog></app-blog>
</div>

<app-view-all-courses></app-view-all-courses>

<div class="premium-access-area ptb-100">
    <app-premium-access></app-premium-access>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
</div> -->