<style>
    .start-with-success-box .start-with-success-box {
        border: 1px solid #ddd;
        margin-bottom: 20px;
        border-radius: 5px;
        overflow: hidden;
    }

    .start-with-success-box img {
        width: 100%;
    }

    .start-with-success-box .content {
        padding: 10px;
    }
</style>

<div
    id="carouselExampleIndicators1"
    class="carousel slide"
    data-ride="carousel"
    data-interval="3000"
>
    <!-- The slideshow -->
    <div class="carousel-inner">
        <div
            class="carousel-item"
            *ngFor="let image of carouselData; let first = first"
            [class.active]="first"
        >
            <img [src]="image.path" [alt]="image.name" class="d-block w-100" />
        </div>
    </div>

    <!-- Left and right controls -->
    <a
        class="carousel-control-prev"
        href="#carouselExampleIndicators1"
        role="button"
        data-slide="prev"
    >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a
        class="carousel-control-next"
        href="#carouselExampleIndicators1"
        role="button"
        data-slide="next"
    >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div>

<div class="courses-area ptb-100">
    <div class="mt-5 p-2 row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="section-title">
                <h2>{{ courseName }} courses</h2>
            </div>
            <div class="container">
                <div class="row">
                    <div
                        class="col-sm-12 col-md-3 col-lg-3 col-xl-3"
                        *ngFor="let course of courses"
                    >
                        <div class="single-courses-box">
                            <div class="courses-image">
                                <img [src]="course.path" alt="image" />
                            </div>
                            <div
                                class="courses-content category-course-content"
                            >
                                <h3 style="font-size: 16px">
                                    {{ course.name }}
                                </h3>
                                <div
                                    class="courses-box-footer"
                                    style="text-align: left"
                                >
                                    <div>
                                        &nbsp;&nbsp;<i
                                            class="flaticon-agenda"
                                            style="color: #fe4a55"
                                        ></i
                                        >&nbsp;&nbsp;
                                        <a
                                            (click)="
                                                reDirectToCoursePage(
                                                    course.name,
                                                    'sample-classes'
                                                )
                                            "
                                            class="align-items-center cursor-pointer"
                                        >
                                            Sample Classes
                                        </a>
                                    </div>

                                    <div>
                                        &nbsp;&nbsp;<i
                                            class="flaticon-people"
                                            style="color: #fe4a55"
                                        ></i
                                        >&nbsp;&nbsp;
                                        <a
                                            (click)="
                                                reDirectToCoursePage(
                                                    course.name,
                                                    'sample-materials'
                                                )
                                            "
                                            class="align-items-center cursor-pointer"
                                        >
                                            Sample Materials
                                        </a>
                                    </div>
                                    <div>
                                        &nbsp;&nbsp;<i
                                            class="flaticon-calendar"
                                            style="color: #fe4a55"
                                        ></i
                                        >&nbsp;&nbsp;
                                        <a
                                            (click)="
                                                reDirectToCoursePage(
                                                    course.name,
                                                    'sample-test'
                                                )
                                            "
                                            class="align-items-center cursor-pointer"
                                        >
                                            Sample Test
                                        </a>
                                    </div>
                                </div>

                                <app-course-fee
                                    [fees]="course.fees"
                                ></app-course-fee>
                                <br />
                                <a
                                    style="margin-left: 20%"
                                    (click)="reDirectToCourse(course._id)"
                                    class="default-btn align-items-center cursor-pointer"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                >
                                    Enroll Now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-title">
                <h2>{{ courseName }} Achievers</h2>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="start-with-success-area bg-f8f9f8">
            <div class="container">
                <div class="row">
                    <div *ngIf="achievers.length === 0" class="empty-message">
                        <p>No achievers available.</p>
                    </div>
                    <div class="row" *ngIf="achievers.length > 0">
                        <div
                            class="col-lg-2 col-md-2 col-sm-6"
                            *ngFor="let item of achievers"
                        >
                            <div class="start-with-success-box">
                                <div class="image">
                                    <!--                                    <a [routerLink]="'/success-story'">-->
                                    <!--                                        <img-->
                                    <!--                                            [src]="item.imgPath"-->
                                    <!--                                            [alt]="item.name"-->
                                    <!--                                        />-->
                                    <!--                                    </a>-->

                                    <img
                                        [src]="item.imgPath"
                                        [alt]="item.name"
                                    />
                                </div>
                                <div
                                    class="content"
                                    style="text-align: center; margin-top: 10px"
                                >
                                    <h3 style="font-size: 17px">
                                        {{ item.name }}
                                    </h3>
                                    <span>{{ item.desc1 }}</span>
                                    <!-- <h3 style="font-size: 17px;">{{ item.desc2 }}</h3> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-subscribe-style-three></app-subscribe-style-three>
