<div class="about-area-three ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <span class="sub-title">Welcome to Future Vision Study Centre</span>
                    <h2>Best Neet academy in salem</h2>
                    <p>For over a decade, Future Vision Study Centre has been a beacon of excellence in education, providing specialized
                        coaching for NEET, TNPSC, and TRB exams in Salem. Our mission is to empower students with the knowledge,
                        skills, and confidence to achieve their academic and career goals.
                        </p>
                    <p><strong>With a dedicated team of experienced faculty and
                        a commitment to quality education, we have established ourselves as the leading coaching institute in the region.</strong></p>
                    <!-- <a routerLink="/courses-1" class="default-btn"><i class="flaticon-user"></i>View All
                        Courses<span></span></a> -->
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-img">
                    <div class="image">
                        <img src="assets/img/about-1.png" alt="image">
                        <img src="assets/img/about-2.png" alt="image">
                    </div>
                    <div class="shape17"><img src="assets/img/shape16.png" alt="image"></div>
                    <div class="shape18"><img src="assets/img/shape17.png" alt="image"></div>
                    <div class="shape19"><img src="assets/img/shape18.png" alt="image"></div>
                    <div class="shape20"><img src="assets/img/shape19.png" alt="image"></div>
                    <div class="shape21"><img src="assets/img/shape20.png" alt="image"></div>
                    <div class="shape22"><img src="assets/img/shape21.png" alt="image"></div>
                    <div class="shape23"><img src="assets/img/shape22.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
</div>

<div class="profile-area pt-50 pb-70">
    <div class="container">
        <div class="profile-box">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4">
                    <div class="image">
                        <img src="assets/img/about-3.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="content">
                        <h3>Mr. Durai Murugan </h3>
                        <span class="sub-title"> Founder and Director</span>
                        <p>Mr. Durai Murugan, the visionary behind Future Vision Study Centre, is a seasoned educator with over 20 years
                            of experience in the field of competitive exam coaching. With a Ph.D. in Education and a deep passion for
                            teaching, Mr. Durai Murugan has dedicated his career to helping students achieve their dreams.
                            Under his leadership, Future Vision Study Centre has grown from a small coaching class to a premier institute in
                            Salem, known for its exceptional results in NEET, TNPSC, and TRB exams. Mr. Durai Murugan 's innovative
                            teaching methods, commitment to excellence, and unwavering dedication to student success have been the driving
                            force behind the institute’s success.
                            Mr. Durai Murugan believes in the power of education to transform lives and is committed to providing students
                            with the best resources, guidance, and support to excel in their exams and future careers. His personal approach and
                            hands-on involvement in the academic progress of each student make Future Vision Study Centre a unique and
                            trusted name in education.</p>


                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="our-story-area ptb-50">
    <div class="container">
        <div class="section-title">

            <h2>Why Us ​</h2>

            <p>Choosing the right coaching institute is crucial for your success. Here’s why Future Vision Study Centre stands out</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number">1</span> Experienced Faculty</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">

                    <p>Our teachers are experts in their respective fields with years of experience in
                        training students for competitive exams.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number">2</span> Comprehensive Study Materials</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">

                    <p>We provide well-researched and up-to-date study materials that cover all aspects of the syllabus.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number">3</span> Personalized Attention</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">

                   <p>With small batch sizes, we ensure each student receives individual attention and
                    tailored guidance.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number">4</span> Proven Track Record</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                   <p>Our students consistently achieve top ranks in NEET, TNPSC, and TRB exams.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number">5</span> State-of-the-Art Facilities</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                   <p>Our classrooms are equipped with modern teaching aids and a conducive learning environment.
                </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number">5</span> Regular Assessments</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                   <p>Frequent tests and assessments help students track their progress and identify areas for improvement.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number">6</span> Affordable Fees</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                   <p>We offer competitive pricing without compromising on the quality of education.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number">7</span> Flexible Learning Options</h3>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                   <p>Choose from classroom coaching or online classes to suit your convenience.
                    .</p>
                </div>
            </div>
        </div>
        <div class="section-title">



            <p>Join Future Vision Study Centre and take the first step towards a successful career. We are committed to turning
                your aspirations into reality.</p>
        </div>
    </div>
</div>

<div class="container">
    <!-- <div class="section-title">
        <span class="sub-title">Education for everyone</span>
        <h2>Affordable Online Courses and Learning Opportunities​</h2>
        <p>Finding your own space and utilize better learning options can result in faster than the traditional ways. Enjoy the beauty of eLearning!</p>
    </div> -->
    <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-5 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-brain-process"></i>
                </div>
                <h3>Mission Statement</h3>
                <p>To empower students with high-quality education and personalized coaching, enabling them to excel in NEET,
                    TNPSC, and TRB exams. We strive to provide comprehensive resources and expert guidance, fostering a learning
                    environment that nurtures confidence, competence, and success.
                    </p>

            </div>
        </div>
        <div class="col-lg-5 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-computer"></i>
                </div>
                <h3>Vision Statement</h3>
                <p>To be the leading coaching institute in Salem, renowned for excellence in education and student success. We
                    envision a future where every student achieves their academic and career goals through our dedicated support,
                    innovative teaching methods, and unwavering commitment to quality education.</p>

            </div>
        </div>
        <div class="col-lg-1"></div>
        <!-- <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-shield-1"></i>
                </div>
                <h3>Learn From Industry Experts</h3>
                <p>Experienced teachers can assist in learning faster with their best approaches!</p>
                <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-world"></i>
                </div>
                <h3>Enjoy Learning From Anywhere</h3>
                <p>We are delighted to give you options to enjoy learning from anywhere in the world.</p>
                <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
            </div>
        </div> -->
    </div>
</div>
