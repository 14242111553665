import {Component, OnDestroy, OnInit} from '@angular/core';
import { MyApiService } from '../../my-api.service';
import { environment } from 'src/app/environment';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-achievers',
  templateUrl: './achievers.component.html',
  styleUrls: ['./achievers.component.scss'] // Corrected from styleUrl to styleUrls
})
export class GalleryAchieversComponent implements OnInit, OnDestroy {
  courses: any[] = [];
  subCourses: any[] = [];
  achievers: any[] = [];
  currentTab = 'Course';
  baseUrl = environment.APP_URL

  constructor(private apiService: MyApiService, private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    this.apiService.getCoursesData().subscribe((res: any) => {
      if (res?.error) {
        throw res.error;
      }
      if (res?.data?.data) {
        this.courses = res?.data?.data;
        if(this.courses.length > 0) {
          this.subCourses = this.courses[0].courses;
          if (this.subCourses.length > 0) {
            this.onSubCourseSelect(null as any, this.subCourses[0]._id); // Pass the first subcourse ID
          }
        }
      }

    });

      // Set the title for the page
      this.titleService.setTitle('Achievers – Future Vision Study Centre');

      // Set the meta description for the page
      this.metaService.updateTag({ name: 'description', content: 'LAVANYA R.K. GOTHAVARI.S' });

      // You can also add other meta tags like keywords, author, etc.
      this.metaService.updateTag({ name: 'keywords', content: 'gallery, achievers, angular, seo, website' });
  }

    ngOnDestroy() {
        // Set the title for the page
        this.titleService.setTitle('Future Vision Study Centre');

        // Set the meta description for the page
        this.metaService.updateTag({ name: 'description', content: 'Welcome to Future Vision Study Centre' });

        // You can also add other meta tags like keywords, author, etc.
        this.metaService.updateTag({ name: 'keywords', content: 'future vision, angular, seo, website' });
    }

  onCourseSelect(event: Event) {
    const selectedCourseId = (event.target as HTMLSelectElement).value;

    const selectedCourse = this.courses.find(course => course._id === selectedCourseId);

    if (selectedCourse && selectedCourse.courses) {
      this.subCourses = selectedCourse.courses;
      if (this.subCourses.length > 0) {
        this.onSubCourseSelect(null as any, this.subCourses[0]._id); // Pass the first subcourse ID
      }
    } else {
      this.subCourses = []; // Clear subCourses if no matching course or subcourses found
    }
  }

  onSubCourseSelect(event: Event, _id?: any) {
    let selectedCourseId;
    if (event) {
      selectedCourseId = (event.target as HTMLSelectElement).value;
    }
    else {
      selectedCourseId = _id
    }

    const findSubCourse  = this.subCourses.find(item => item._id == selectedCourseId)
    this.currentTab = findSubCourse?.name

    this.apiService.getAchieverData( { courseIds: [selectedCourseId] } ).subscribe((res: any) => {
      if (res?.error) {
        throw res.error;
      }
      this.achievers = res?.data?.achievers;

    });
  }

  trackByCourseId(index: number, course: any): string {
    return course._id;
  }

  trackBySubCourseId(index: number, subcourse: any): string {
    return subcourse._id;
  }
}
