import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Title, Meta } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';

import { ActivatedRoute } from '@angular/router';
import { MyApiService } from '../../my-api.service';

@Component({
    selector: 'app-sample-video',
    templateUrl: './sample-video.component.html',
    styleUrl: './sample-video.component.scss',
})
export class SampleVideoComponent implements OnInit {
    _album: { name: string; videos: { url: string; name: string }[] }[] = [];
    courseName: string;
    categoryName: string;
    courseDetails: {
        examsCount: number;
        materialsCount: number;
        videosCount: number;
        relatedCourses: {
            _id: string;
            name: string;
            category: { _id: string; name: string };
        }[];
    };

    servicesSlides: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 10, // Keep margin small for close gaps
        stagePadding: 20, // Reduced stage padding for smaller items
        navText: [
            "<i class='flaticon-chevron'></i>",
            "<i class='flaticon-right-arrow'></i>",
        ],
        responsive: {
            0: {
                items: 1, // 1 item for mobile
                stagePadding: 30, // Small padding for mobile
            },
            300: {
                items: 2, // 2 smaller items
            },
            600: {
                items: 3, // 3 items on medium screens
            },
            900: {
                items: 5, // 3 items on larger screens
            },
            1200: {
                items: 7, // 4 smaller items on large screens
                stagePadding: 30, // Keep items centered
            },
        },
    };


    constructor(
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private myApiService: MyApiService,
        private titleService: Title,
        private metaService: Meta
    ) {}

    ngOnInit(): void {
        this.route.queryParamMap.subscribe((params) => {
            // 'id' should match the query parameter name

            this.route.paramMap.subscribe((params) => {
                this.courseName = params.get('courseName');
                this.categoryName = params.get('categoryName');
                if (this.courseName) {
                    this.loadPageData();
                    this.getCourseDetails();
                    this.titleService.setTitle(
                        `${this.courseName} - Sample Classes`
                    );

                    // Set the meta description for the page
                    this.metaService.updateTag({
                        name: 'description',
                        content: `${this.courseName} Sample Classes`,
                    });

                    // You can also add other meta tags like keywords, author, etc.
                    this.metaService.updateTag({
                        name: 'keywords',
                        content: 'sample-classes, angular, seo, website',
                    });
                }
            });
        });
    }

    loadPageData() {
        this.myApiService
            .getSampleVideoData({
                courseNames: [this.courseName],
            })
            .subscribe(({ data: { list = [] } = {} }) => {
                this._album = [];

                for (let i = 0; i < list.length; i++) {
                    const {
                        material: { url = '', name: title = '' } = {},
                        subject: { name = '-' } = {},
                    } = list[i];

                    const subject = this._album.find(
                        (album) => album.name === name
                    );
                    if (subject) {
                        subject.videos.push({ url, name: title });
                    } else {
                        this._album.push({
                            name,
                            videos: [{ url, name: title }],
                        });
                    }
                }
            });
    }

    private getCourseDetails() {
        this.courseDetails = undefined;
        this.myApiService
            .getCourseDetails(this.courseName)
            .subscribe(({ data = {} }) => {
                this.courseDetails = data;
            });
    }

    videoUrl: SafeResourceUrl;
    isModalVisible = false;
    openModal(videoId: string) {
        const url = `https://www.youtube.com/embed/${videoId}?autoplay=1`;
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.isModalVisible = true;
    }

    closeModal() {
        this.isModalVisible = false;
        // Optionally reset iframe source to stop video
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    }

    getThumbnailUrl(videoId: string): string {
        return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    }
}
