<div class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div *ngIf="_album.length === 0" class="empty-message">
                <p>No materials available.</p>
            </div>

            <div *ngIf="_album.length > 0" class="row">
            <div class="col-lg-2 col-xl-2 col-md-6 col-sm-6" *ngFor="let img of _album; let i = index;">
                <div class="single-courses-box" (click)="reDirectToNewTab(img.attachment)">
                    <div class="courses-image">
                        <a class="d-block image">
                            <img [src]="img.imgUrl" alt="image">
                        </a>
                    </div>
                    <div class="courses-content">
                        <h3 style="font-size: 16px;">{{img.name}}</h3>
                        <br>
                    </div>
                </div>
            </div>
            </div>
        </div>

        <div class="col-lg-12 col-md-12">
            <div class="pagination-area text-center">
                <a *ngIf="currentPage > 1" (click)="onPageChange(currentPage - 1)" class="prev page-numbers">
                    <i class='bx bx-chevrons-left'></i>
                </a>

                <span *ngFor="let page of visiblePages" (click)="onPageChange(page)"
                    [ngClass]="{'page-numbers': true, 'current': page === currentPage}"
                    [attr.aria-current]="page === currentPage ? 'page' : null">
                    {{ page }}
                </span>

                <a *ngIf="currentPage < totalPages" (click)="onPageChange(currentPage + 1)" class="next page-numbers">
                    <i class='bx bx-chevrons-right'></i>
                </a>
            </div>

        </div>
    </div>
</div>
