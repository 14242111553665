import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-course-fee',
    templateUrl: './course-fee.component.html',
    styleUrl: './course-fee.component.scss',
})
export class CourseFeeComponent {
    @Input() fees: { type: string; fee: number }[] = [];
}
