<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contact</li>
            </ul>
            <h2>Contact</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div> -->

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Contact Details</span>
                    <h2>Get in Touch</h2>
                   
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Our Address</h3>
                            <p>FUTURE VISION STUDY CENTRE, 2nd Floor, AVK Maruthi Plaza, Opp.Hotel Lakshmi Prakash, New Bus Stand, Salem-636004 </p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contact</h3>
                            <p>Mobile: <a href="tel:+919042030163">(+91) - 90420 30163 </a></p>
                            <p>Mail: <a href="mailto:futurevisionteam&#64;gmail.com">futurevisionteam&#64;gmail.com</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Hours of Operation</h3>
                            <p>Monday - Saturday: 09:00 AM - 06:00 PM</p>
                            <p>Sunday : 09:00 AM - 04:00 PM</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Ready to Get Started?</h2>
                 
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Your name">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Name is required.</div>
                                        <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="Your email address">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                                </div>
                            </div> -->
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="location" type="text" class="form-control" id="location" placeholder="Your location">
                                    <!-- <div class="alert alert-danger" *ngIf="location.touched && !name.valid">Message is required.</div> -->
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" minlength="10" maxlength="10" #number="ngModel" class="form-control" id="number" placeholder="Your phone number">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="Write your message..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Send Message<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2000569.7038301607!2d78.142892!3d11.668687!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfce1120fb2fc5bc2!2sFUTURE%20VISION%20BEST%20NEET%20%2F%20TNPSC%20%2F%20PG.TRB%20%2F%20UG.TET%20%2F%20AO-AGRI%20s%2C%20Bank%20Exams%20COACHING%20CENTRE%20SALEM!5e0!3m2!1sen!2sin!4v1670069930407!5m2!1sen!2sin&z=10"  width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d260370.4624153429!2d-74.21074577271956!3d40.67735855419542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25090129c363d%3A0x40c6a5770d25022b!2sStatue%20of%20Liberty%20National%20Monument!5e0!3m2!1sen!2sbd!4v1599386259810!5m2!1sen!2sbd"></iframe> -->
</div>