<div class="navbar-area" [ngClass]="{ sticky: isSticky }">
    <div class="container-fluid">
        <nav
            class="navbar navbar-expand-lg navbar-light bg-light"
            [class.active]="classApplied"
        >
            <a class="navbar-brand" routerLink="/"
                ><img
                    src="assets/img/logowide.png"
                    width="120"
                    height="40"
                    alt="logo"
            /></a>
            <ul class="social-link d-flex fs-3 social-link hide-on-mobile">
                <li>
                    <a
                        href="https://www.facebook.com/futurevisionneet"
                        class="d-block cursor-pointer"
                        target="_blank"
                        ><i class="bx bxl-facebook"></i
                    ></a>
                </li>
                <li>
                    <a
                        href="https://www.instagram.com/future_vision_study_centre "
                        class="d-block cursor-pointer ml-2"
                        target="_blank"
                        ><i class="bx bxl-instagram"></i
                    ></a>
                </li>
                <li>
                    <a
                        href="https://www.youtube.com/@FUTUREVISIONNEETACADEMY-jg3qr"
                        class="d-block cursor-pointer ml-2"
                        target="_blank"
                        ><i class="bx bxl-youtube"></i
                    ></a>
                </li>

                <li>
                    <a
                        href="https://t.me/futurevisionneetacademy"
                        class="d-block cursor-pointer ml-2"
                        target="_blank"
                        ><i class="bx bxl-telegram"></i
                    ></a>
                </li>
            </ul>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a routerLink="/" class="nav-link">Home </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/about" class="nav-link">About us</a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link"
                            >Courses <i class="bx bx-chevron-down"></i
                        ></a>
                        <ul class="dropdown-menu">
                            <li *ngFor="let course of courses">
                                <a
                                    [routerLink]="['/courses/' + course.name]"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                >
                                    {{ course.name }}
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link"
                            >Gallery <i class="bx bx-chevron-down"></i
                        ></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a
                                    routerLink="/gallery/achievers"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Achievers</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/gallery/infra"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Infrastructure</a
                                >
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/contact" class="nav-link"
                            >Contact us
                        </a>
                    </li>
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item">
                        <a routerLink="/" class="nav-link">Home </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/about" class="nav-link"
                            >About us</a
                        >
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link"
                            >Courses <i class="bx bx-chevron-down"></i
                        ></a>
                        <ul class="dropdown-menu">
                            <li *ngFor="let course of courses">
                                <a
                                    [routerLink]="['/courses/' + course.name]"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                >
                                    {{ course.name }}
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link"
                            >Gallery <i class="bx bx-chevron-down"></i
                        ></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a
                                    routerLink="/gallery/achievers"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Achievers</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/gallery/infra"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Infrastructure</a
                                >
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/contact" class="nav-link"
                            >Contact us
                        </a>
                    </li>
                </ul>
            </div>
            <div class="others-option d-flex align-items-center">
                <div class="option-item">
                    <a
                        href="https://futurevisioniasacademy.com/auth/sign-up"
                        target="_blank"
                        class="default-btn"
                        ><i class="flaticon-user"></i>Login/Register<span></span
                    ></a>
                </div>
            </div>
        </nav>
    </div>
</div>
