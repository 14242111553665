import {Component, OnDestroy, OnInit} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Lightbox } from 'ngx-lightbox';
import { MyApiService } from '../../my-api.service';
import { environment } from 'src/app/environment';

@Component({
  selector: 'app-infra',
  templateUrl: './infra.component.html',
  styleUrls: ['./infra.component.scss'] // Corrected: styleUrls should be plural
})
export class InfraComponent implements OnInit, OnDestroy {

  public _album: any[] = [];
  public _infra: any[] = [];
  baseUrl = environment.APP_URL;

  constructor(private apiService: MyApiService, private _lightbox: Lightbox, private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.apiService.getGalleryInfrastructureData().subscribe((res: any) => {
      if (res?.error) {
        throw res.error;
      }
      this._infra = res?.data?.infrastructures;
      console.log(this._infra)
      // Prepare the album for the lightbox
      let i = 0;
      this._infra.forEach(infra => {
        infra.attachments.forEach(image => {
          image['index']=i;
          const album = {
            src: this.baseUrl + image.path,
            thumb: this.baseUrl + image.path,
            caption: infra.name,
            index: i
          };
          i++;
          this._album.push(album);
        });
      });
      console.log(this._infra)
    });

      // Set the title for the page
      this.titleService.setTitle('Infrastructure – Future Vision Study Centre');

      // Set the meta description for the page
      this.metaService.updateTag({ name: 'description', content: 'Hostel Facility. Classroom Facilities' });

      // You can also add other meta tags like keywords, author, etc.
      this.metaService.updateTag({ name: 'keywords', content: 'infrastructure, angular, seo, website' });
  }

    ngOnDestroy() {
        // Set the title for the page
        this.titleService.setTitle('Future Vision Study Centre');

        // Set the meta description for the page
        this.metaService.updateTag({ name: 'description', content: 'Welcome to Future Vision Study Centre' });

        // You can also add other meta tags like keywords, author, etc.
        this.metaService.updateTag({ name: 'keywords', content: 'future vision, angular, seo, website' });
    }

  open(index: number): void {
    // open lightbox with the correct album
    this._lightbox.open(this._album, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
}
