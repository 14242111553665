<div class="gallery-area pb-70">
    <div class="container" *ngFor="let infra of _infra">
        <div class="page-title-content">
            <h2 class="pb-70">{{ infra.name }}</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let image of infra.attachments;">          
                <div class="single-gallery-item">                 
                    <img alt="{{ image.name }}" [src]="baseUrl + image.path" (click)="open(image.index)" />
                </div>
            </div>
        </div>
    </div>
</div>